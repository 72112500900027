import { Row, Col, Hidden } from "react-grid-system";
import SectionBox from "../Section";
import util_1 from '../../../Images/ItemMagic.png';
import util_2 from '../../../Images/ItemPinky.png';
import util_3 from '../../../Images/ItemCoin.png';
import util_4 from '../../../Images/ItemEth.png';

function ProjUtils(props){
    return(
       <SectionBox id={props.id}>        
           <Col lg={11} xl={10} xxl={10}>
                <div className="inner">          
                    <div className="utility">
                        <Row justify="center" align="start" gutterWidth={64}>
                            <Col xs={6} md={3} lg={3}>
                                <div className="util">
                                    <img src={util_1} alt="Punch It Bru" />
                                    <h4>Community IP</h4>
                                    <p>You own the IP of your NFT for any use and exclusive access</p>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="util">
                                    <img src={util_3} alt="Punch It Bru" />
                                    <h4>Play to Earn</h4>
                                    <p>Play the 2D platformer and convert rewards into $BRU tokens</p>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="util">
                                    <img src={util_4} alt="Punch It Bru" />
                                    <h4>Multi Utility</h4>
                                    <p>Cross project utility on the ETH chain with your NFT</p>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="util">
                                    <img src={util_2} alt="Punch It Bru" />
                                    <h4>Original Art</h4>
                                    <p>Enjoy ownership of original artwork, characters &amp; story arcs</p>
                                </div>
                            </Col>  
                            <Hidden xs sm>   
                                 <p>&nbsp;</p>
                                 <p>&nbsp;</p>
                            </Hidden>
                        </Row>
                    </div>
                </div>
           </Col>  
       </SectionBox>
    )
}

export default ProjUtils