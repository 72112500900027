import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import logo from '../../Images/logo.png'
import { Socials } from "../Common";

const links = [
    "Game",
    "Comic",
    "Meme",
    "FAQs",
    "The Brus"
]

function Nav(){
    return (
        <nav>
            <Container fluid>
                <Row justify="between" align="center" direction="row" className="nav-row">
                    <Col xs={9} md={3} lg={3} xl={2}>
                        <div className="logo-box">
                            <img src={logo} alt="logo" />
                            <h1 className="logo-text">PUNCH IT BRU</h1>
                        </div>
                    </Col>
                    <Hidden xs sm md>
                        <Col lg={7} xl={6} align="center">
                            <Row>
                                {
                                    links.map((e,i) => {
                                        return (
                                            <Col key={i}><a href={'#' + e}>{e}</a></Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                    </Hidden>
                    <Col xs={3}  md={3} lg={1} xl={2} align="end">
                        <a href="/" className="connect">Connect</a>
                    </Col>
                </Row>
            </Container>

            <Visible lg xl xxl>
                <div className="floating-socials">
                    <Socials />
                </div>
            </Visible>
        </nav>
    )
}

export default Nav