import { ScreenClassProvider } from "react-grid-system";
import Nav from "./Nav";
import About from "./Sections/About";
import FAQ from "./Sections/FAQ";
import Game from "./Sections/Game";
import Landing from "./Sections/Landing";
import Meme from "./Sections/Meme";
import Story from "./Sections/Story";
import Team from "./Sections/Team";
import ProjUtils from "./Sections/Utils";

function App() {

  const rndInt = (Math.floor(Math.random() * 6) + 1) -1

  return (
    <ScreenClassProvider>
      <Nav />
      <Landing id={'Home'} />
      <ProjUtils id={'NFTUtil'} />
      <Game id={'Game'} fluid={true} />
      <Story id={'Comic'} />
      <Meme id={'Meme'} rando={rndInt} />
      <FAQ id={'FAQs'}/>
      <Team id={'The Brus'} />
      <About />
    </ScreenClassProvider>
  );
}

export default App;
