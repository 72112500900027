import { Container, Row } from "react-grid-system"

function SectionBox(props){
    return (
        <section className={props.bg} id={props.id}>
            <Container fluid={props.fluid}>
                <Row  align="center" justify="center" direction="row" className="pib-row">
                    {props.children}
                </Row>
            </Container>
        </section>
    )
}

export default SectionBox