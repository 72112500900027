import { useState, useRef } from "react";
import html2canvas from "html2canvas";
import { Row, Col } from "react-grid-system";
import SectionBox from "../Section";
import memer1 from '../../../Images/meme/Rafulu1.png';
import memer2 from '../../../Images/meme/Rafulu2.png';
import memer3 from '../../../Images/meme/Rafulu3.png';
import memer4 from '../../../Images/meme/Rafulu4.png';
import memer5 from '../../../Images/meme/Rafulu5.png';
import memer6 from '../../../Images/meme/Rafulu6.png';


function Meme(props){

    const [memeText, setMemeText] = useState('...')
    const [yourTwitter, setYourTwitter] = useState('')
    const memeimg = [
        memer1, memer2, memer3, memer4, memer5, memer6
    ]

    const printRef = useRef();

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'My_Rafulu';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        } else {
        window.open(data);
        }
    };


    return(
        <SectionBox id={props.id}>        
        <Col lg={11} xl={10} xxl={9}>
             <div className="inner">
                 <div className="spacer"></div>
                 <h2>😈 MEME DeGen</h2>
                 <p>&nbsp;</p>
                 <div className="memegen">
                     <Row justify="between" align="start">
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="inner-left">
                                <h3>Join the movement<br/>tweet your own Rafulu Meme.</h3>
                                <small className="memesmall">
                                👇 punch in your comment / phrase.<br/> 
                                👇 punch in your twitter handle.<br/> 
                                </small><br/>
                                <input type="text" onChange={(e)=> setMemeText(e.target.value) } placeholder="💬 type meme text here"/>
                                <input type="text" onChange={(e)=> setYourTwitter(e.target.value) } placeholder="🐦 your @twitter handle here"/>
                                <button disabled={(yourTwitter.length < 4 || memeText.length < 4) ? true: false } className="punchit" onClick={handleDownloadImage}>
                                    {/* <img src={butt} alt="butt button" width={'100%'} /> */}
                                    PUNCH IT!
                                </button>
                                <p className="download">☝️ 'Punch It' to save meme.<br/> 🤙 Don't forget to tweet it BRU!</p>
                                <br/><br/>
                                {/* {
                                    memeText.length < 4 ? null : (
                                        <button className="download" onClick={handleDownloadImage}>
                                        👉🏻 <span>Download Image &amp; Tweet</span>
                                        </button>
                                    )
                                } */}
                            </div> 
                        </Col>
                         <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="meme" ref={printRef}>
                                <div className="meme-text">
                                    {memeText === ''? '...' : memeText}
                                </div>
                                <div className="meme-img">
                                    <img src={memeimg[props.rando]} width="100%" alt="meme" />
                                </div>
                                <div className="fter">
                                    <small>@punchitbru</small>
                                </div>
                                <div className="fteruser">
                                    <small>@{yourTwitter}</small>
                                </div>
                            </div>
                         </Col>
                     </Row>
                 </div>
             </div>
        </Col>  
    </SectionBox>
    )
}

export default Meme