function GameBar(props){
    return(
    <div className="progress">
        <div className="bar" style={{'width': props.perc}}></div>
        <div className="reading">
            <span>Investment from NFT Sale</span>
            <span>{props.perc}</span>
        </div>
    </div>
    )
}

export default GameBar