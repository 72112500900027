import { Row, Col } from "react-grid-system";
import SectionBox from "../Section";
import teamone from '../../../Images/team_one.png';
import teamfour from '../../../Images/team_four.png';
import teamthree from '../../../Images/team_three.png';

function Team(props){
    return(
       <SectionBox id={props.id}>        
           <Col lg={11} xl={10} xxl={7}>
                <div className="inner">
                    <div className="spacer"></div>
                    <h2>The BRUs</h2>
                    {/* <Row justify="center">
                        <Col lg={11} xl={10} xxl={8}>          
                            <div className="progress">
                                <div className="bar" style={{'width': '30%'}}></div>
                                <div className="reading">
                                    <span>NFT Sale</span>
                                    <span>30%</span>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <h3>HIya! we're the Brus behind the project.</h3>
                    <p>Follow us on <a href="twtiier.com/@punchitbru" target="_blank">Twitter</a> to get news as it happens or come chat to us on our <a href="twtiier.com/@punchitbru" target="_blank">Discord</a>.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    
                    <Row gutterWidth={64}>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="team">       
                                <img src={teamone} alt="team" width="100%" />
                                <span>Megastar  Moonkey</span>
                                <a href="https://twitter.com/@megastarmoonkey" target="_blank" rel="noreferrer">@megastarmoonkey</a>
                                <p>Designer extrodinare and all around nuce guy. He is the heart and soul of all things PIB and 'Rafulu'. </p>
                                <p>&nbsp;</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="team">       
                                <img src={teamfour} alt="team" width="100%" />
                                <span>Monkey Magic</span>
                                <a href="https://twitter.com/@sifu0x" target="_blank" rel="noreferrer">@punchitbru</a>
                                <p>The Jack of all trades, brainstorming and planning new and awesomer things for PIB. Game, NFTs etc</p>
                                <p>&nbsp;</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="team">       
                                <img src={teamthree} alt="team" width="100%" />
                                <span>Stinky Monkey</span>
                                <a href="https://twitter.com/@megastarmoonkey" target="_blank" rel="noreferrer">@punchitbru</a>
                                <p>The resident tech dude. Everything from Smart contracts to Discord Bots. Stinkingly good at tech.</p>
                                <p>&nbsp;</p>
                            </div>
                        </Col>
                    </Row>
                </div>
           </Col>  
       </SectionBox>
    )
}

export default Team