import { Row, Col, useScreenClass, Visible } from "react-grid-system";
import SectionBox from "../Section";
import GameBar from "../Game/gamebar";
import t1 from '../../../Images/Story/01.png';
import t2 from '../../../Images/Story/02.png';
import t3 from '../../../Images/Story/03.png';
import t4 from '../../../Images/Story/04.png';
import t5 from '../../../Images/Story/05.png';
import t6 from '../../../Images/Story/06.png';
import t7 from '../../../Images/Story/07.png';
import t8 from '../../../Images/Story/08.png';
import t9 from '../../../Images/Story/09.png';
import t10 from '../../../Images/Story/10.png';
import t11 from '../../../Images/Story/11.png';
import book from '../../../Images/book.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'

function Story(props){

    const screenClass = useScreenClass()

    return(
       <SectionBox id={props.id}>        
           <Col lg={11} xl={8} xxl={9}>
                <div className="inner">
                    <div className="spacer"></div>
                    <h2>in a multi-metaverse<br/>far far away ...</h2>
                    <Row justify="center">
                        <Col xl={8} xxl={8} align="center">
                            <h3>Follow Rafulu the meta travelling monkey on his muti-metaverse adventures as he battles with his arch nemesis, the evil Dr Green Thumb.</h3>
                            <p>&nbsp;</p>
                        </Col>
                    </Row>

                    <div className={['xs', 'sm'].includes(screenClass) ? "story mobi" : "story"}>
                        <Carousel
                            // dynamicHeight={true}
                            emulateTouch={true}
                            infiniteLoop={true}
                            showStatus={false}
                            showArrows={false}
                            autoPlay={true}
                            showThumbs={false}
                        >
                            <Row align="center" justify="center">
                                <Col xs={6} md={6} lg={6} xl={6} xxl={6}><img src={t1} alt="one" /></Col>
                                <Col xs={6} md={6} lg={6} xl={6} xxl={6}><img src={t2} alt="one" /></Col>

                                <Col xs={12} md={12} lg={12} xl={12} xxl={12}><img src={t3} alt="one" /></Col>
                            </Row>
                            <Row align="center" justify="center">
                                <Col xs={4} md={4} lg={4} xl={4} xxl={4}><img src={t4} alt="one" /></Col>
                                    <Col xs={8} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align="center" justify="start"  direction="row">                   
                                        <Col xs={6} md={6} lg={6} xl={6} xxl={6}><img src={t5} alt="one" /></Col>
                                        <Col xs={6} md={6} lg={6} xl={6} xxl={6}><img src={t6} alt="one" /></Col>
                                        <Col xs={12} md={12} lg={12} xl={12} xxl={12}><img src={t7} alt="one" /></Col> 
                                    </Row>
                                </Col>
                            </Row>
                            <Row align="center" justify="center">
                                <Col xs={7} md={7} lg={7} xl={7} xxl={7}><img src={t8} alt="one" /></Col>
                                <Col xs={5} md={5} lg={5} xl={5} xxl={5}><img src={t9} alt="one" /></Col>
                                <Col xs={5} md={5} lg={5} xl={5} xxl={5}><img src={t10} alt="one" /></Col>
                                <Col xs={7} md={7} lg={7} xl={7} xxl={7}><img src={t11} alt="one" /></Col>
                            </Row>
                        </Carousel>
                        
                        <br/>
                        <Visible md lg xl xxl>
                            <p>&nbsp;</p>
                        </Visible>
                    </div>
                </div>
           </Col>  

           <Col lg={11} xl={10} xxl={9}>
                <Row justify="between" align="center">
                    <Col lg={7} xl={7} xxl={6}>
                        <div  align={['xs', 'sm'].includes(screenClass) ? "center" : " "}>
                            <h3 className="Teko">Next level Comic NFTs<br/></h3>

                            <GameBar perc={'20%'} />

                            <h3>Destiny is bollocks. Who knows what mental folklores will be created in our Discord, all we know is that they’ll be immortalised on the chian ...forever</h3>
                            <p>20% of our NFT sales will be invested into creating the first chapter of the ‘Punch It Bru’ saga where the first 1,000 issues will be distributed among our VIP members.</p>
                            <p>🙋 Watch out for story competitions and voting.</p>
                            <p>&nbsp;</p>
                        </div>
                    </Col>
                    <Col lg={4} xl={4} xxl={5}>
                        <div className="inner">
                            <img src={book} alt="comic book" width="100%" />
                        </div>
                    </Col>
                </Row>
                
                <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                </Visible>
           </Col>  
       </SectionBox>
    )
}

export default Story