import { Col, Row, Visible, useScreenClass } from "react-grid-system";
import SectionBox from "../Section";
import GameBar from "./gamebar";

import game_bg from '../../../Images/game_bg_alt.png';
import game_pane from '../../../Images/game_bg.png';
import game_locked from '../../../Images/locked.png';
import game_coin1 from '../../../Images/PIB_Icon_pixel_Booster.png';
import game_coin2 from '../../../Images/PIB_Icon_pixel_Currentcy.png';
import game_coin3 from '../../../Images/PIB_Icon_pixel_Hint.png';
import game_coin4 from '../../../Images/PIB_Icon_pixel_Loot.png';

const features = [
    {
        pic: game_coin2,
        title: "$BRU coins",
        txt: "Play to earn $BRU coins &amp; buy badass power-ups, weapons and support characters"
    },
    {
        pic: game_coin4,
        title: "Loot Shop",
        txt: "Use your $BRU tokens to buy upgrades and unlock special items as you play."
    },
    {
        pic: game_coin3,
        title: "NFT Boosters",
        txt: "Stake your Rafulu NFT and unlock companion characters to play bonus levels."
    },
    {
        pic: game_coin1,
        title: "Comic Hints",
        txt: "Discover in-game easter eggs and puzzle hints in the PIB Comic series."
    }
]

function Game(props){

    const screenClass = useScreenClass()
    console.log(screenClass)

    return(
       <SectionBox id={props.id} fluid={props.fluid}>        
           <Col lg={11} xl={10} xxl={12}>
                <Row>
                    <Col xxl={2}></Col>
                    <Col xxl={4} align={['xs', 'sm'].includes(screenClass) ? "center" : " "}>
                        <div>
                            <h2>Play to Earn<br/> 2D Platformer</h2>
                            <GameBar perc={'50%'} />
                            <h3>Play as your Rafulu(NFT) using his unique traits across the multi-metaverse game levels.</h3>
                            <p>50% of the genesis NFT sales will be invested into creating a browser based classic ‘Beat em Up’ 2D platformer with an exclusive leaderboard for all NFT owners and the ability to earn $BRU tokens as you play allowing you to buy badass power-ups, weapons and support characters.</p>
                            <p>😎 PLUS! Players can stake their NFTs to unlock super-secret bonus levels AND we will collaborate with our community to design each additional level. Really really.</p>
                            <p>&nbsp;</p>
                        </div>
                    </Col>
                    <Col xxl={0.5}></Col>
                    <Col xxl={5.5} style={{'paddingRight': ['xs', 'sm', 'md'].includes(screenClass) ? '15px' : '0'}}>
                        <img src={game_bg} alt="gm" width={'100%'} className="gamecell"  />
                    </Col>
                </Row>
           </Col>  

           <Col lg={11} xl={10} xxl={8}>
                <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                </Visible>
                <p>&nbsp;</p>
                <Row gutterWidth={64}>
                    {
                        features.map((e,i) => {
                            return (
                                <Col md={12} lg={3} xl={3} xxl={3} key={i} align={['xs', 'sm', 'md'].includes(screenClass) ? null : 'center'}>
                                    <Row justify="around">
                                        <Col xs={2} sm={3} md={2} lg={12} className="ingame" align="center">
                                            <img src={e.pic} alt="item" width="100%" className="gameutil-img" />
                                        </Col>
                                        <Col xs={8} sm={9} md={10} lg={12} className="ingame">
                                            <h3 className="game-util-h3">{e.title}</h3>
                                            <p className="game-util-txt">{e.txt}</p>
                                            <Visible lg><p>&nbsp;</p></Visible>
                                        </Col>
                                    </Row>
                                    <Visible xs sm md>
                                        <hr />
                                    </Visible>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                </Visible>
                <p>&nbsp;</p>
           </Col>   
           <Col lg={11} xl={10} xxl={8}>
                <Row justify="center">
                    <Col lg={7} xl={7} xxl={10}>
                        <div className="inner">
                            <h3 className="Teko">More multi-metaverse levels to come 👀</h3>
                        </div>
                <p>&nbsp;</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} lg={4} xl={4}>
                        <img src={game_pane} alt="gm" width={'100%'} className="gamecell"/>
                        <p className="game-level">Toxic Jungle</p>
                    </Col>
                    <Col md={4} lg={4} xl={4}>
                        <img src={game_locked} alt="gm" width={'100%'} className="gamecell" />
                        <p className="game-level">Space Wars</p>
                    </Col>
                    <Col md={4} lg={4} xl={4}>
                        <img src={game_locked} alt="gm" width={'100%'} className="gamecell" />
                        <p className="game-level">Stormy Seas </p>
                    </Col>
                </Row>
                <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </Visible>
           </Col> 
           
       </SectionBox>
    )
}

export default Game