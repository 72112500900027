import { Col } from "react-grid-system";
import SectionBox from "../Section";

function About(props){
    return(
       <SectionBox id={props.id}>        
           <Col lg={11} xl={10} xxl={6}>
               <div className="inner">
               <small className="footer-txt"><b>PUNCH IT BRU!</b> is owned soley by the founders of the project.<br/> All content and copy is subject to copyright and may not be reproduced or used for any commerical purposes without the explicit concent of the project owners. Information on the website is current at the time of publication. For real time updates follow us on Twitter or join the Discord.</small>
               </div>
           </Col>  
       </SectionBox>
    )
}

export default About