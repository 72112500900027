import { useState } from "react";
import { Col, Visible } from "react-grid-system";
import SectionBox from "../Section";
import minus from "../../../Images/minus.svg"
import plus from "../../../Images/plus.svg"

const qnada = [
    {
        "q": "What the heck is Punch It Bru?",
        "a": `<b>Punch It Bru</b> (PIB), at it's core is a classic 2D platformer that is based in the world of web 3.0. Gameplay is accessible to PIB NFT holders.<br/><br/>Rewards collected within the game can be exchanged for 🌟$BRU, our own ERC20 Token. Players can use $BRU coins to buy upgrades, weapons and secondary characters. Our mission is to build a PIB entertainment ecosystem.`
    },
    {
        "q": "How is the Rafulu collection distributed?",
        "a": `🐵 <b>#8888</b> - Total Collection<br/><br/> 🚀 <b>#3000</b> - among <b>500</b> Whitelist members. Competition will be announced soon.<br/> 🚀 <b>#5288</b> - Public Sale <br/> 🚀 <b>#600</b> - reserved for community rewards, collaborations and founders.`
    },
    {
        "q": "Is there a guaranteed mint whitelist? ",
        "a": `Yes, the whitelist raffle and competitions will be announced soon. <br/> Follow us on <a href="twtiier.com/@punchitbru" target="_blank">Twitter</a> or join our <a href="twtiier.com/@punchitbru" target="_blank">Discord</a> for news as it happens.`
    },
    {
        "q": "What is the maximum mint allowed for whitelist?",
        "a": `There is no limit to how much you can mint during the whitelist event. However, the maximum mint supply for whitelist is limited to #3000.`
    },
    {
        "q": "How does utility work for the collection?",
        "a": `<b>🎮 Play to Earn</b>:<br/>Owners of NFTs get access to the <b>'PUNCH IT BRU'</b> platformer game. Complete each level, collect coins and find hidden objects to earn $BRU tokens. Each NFT will have unique characteristics that will help complete some level easily. The comic book will hold clues and answers to puzzles, loot and bonus level in the game.<br/><br/><b>📓 Comic Book Ownership</b>: <br/>Owners of the NFT will be entered into a raffle to win the first issue of the <b>'PUNCH IT BRU'</b> comic book. First edition comics will be limited to 1000 units.`
    },
    {
        "q": "What's the max supply of 🌟 $BRU tokens?",
        "a": `The total supply of 🌟 $BRU tokens will be limited to 100,000,000. These will get distributed the players of the <b>'PUNCH IT BRU'</b> platform game.`
    },
    {
        "q": "What are 🌟 $BRU tokens? and how do i get them?",
        "a": `🌟 $BRU tokens are the native currency of the <b>"Punch It BRU"</b> (PIB)  eco-system. It is a ERC20 utility token accessible to only PIB NFT holders used for ecosystem transactions.`
    }
    
]

function Item(props){

    const [toggle, setToggle] = useState(true)

    return (
        <div className="item" onClick={() => setToggle(!toggle)}>
            <div className="q" >
                <span><var>🤔</var> {props.q}</span>
                <span className="tog">
                    {toggle ? <img src={plus} alt="plus" /> : <img src={minus} alt="plus" />}
                </span>
            </div>
            <div className={"a " + (toggle ? "hide" : "show")} dangerouslySetInnerHTML={{__html: props.a}}></div>
        </div>
    )
}

function FAQ(props){

    return(
       <SectionBox id={props.id}>        
           <Col lg={11} xl={10} xxl={7}>        
                 <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </Visible> 

                <div className="inner">
                    <div className="spacer"></div>
                    <h2>FAQs</h2>
                    <p>&nbsp;</p>
                    {
                        qnada.map((e,i) => {
                            return (
                                <Item key={i} q={e.q} a={e.a} />
                            )
                        })
                    }
                </div>  

                <Visible md lg xl xxl>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </Visible> 
           </Col> 
       </SectionBox>
    )
}

export default FAQ