import { Col, Container, Hidden, Row, useScreenClass  } from "react-grid-system";
// import cover from '../../../Images/pib_cover.png';
import piblogo from '../../../Images/piblogo.png'

function Landing(props){
    const screenClass = useScreenClass()
    return(
       <>
            <section className={['xs', 'sm'].includes(screenClass) ? "cover mobi" : "cover"} id="Home">
                {/* <div className="landing-img">
                    <img src={cover} alt="Punch It Bru" />
                </div> */}
            </section>
            <section className="cover-bottom">    
                 <Container>
                    <Row justify="center" direction="row">
                        <Col md={10} lg={8} xl={10} xxl={7}>
                            <div className="inner">
                                <div className="spacer"></div>
                                {/* <h1>Punch It Bru</h1> */}
                                <img src={piblogo} alt="PUNCH IT BRU" width={'100%'} />
                                <h3>An NFT collection designed to bring the classic platformer genre to the metaverse. Built for utility,  powered by the community.</h3>
                            </div>
                            <Hidden xs sm>    
                                <p>&nbsp;</p>
                            </Hidden>
                        </Col>   
                    </Row>
                </Container>
            </section>
       </>
    )
}

export default Landing